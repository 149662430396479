import ApiService from "@/services";

const state = {
  pending: true
};

const getters = {};

const mutations = {
  setPending(state, value) {
    state.pending = value
  }
};

const actions = {
  async initiateBookingOnRequest({ commit }, payload) {
    commit('setPending', true);
    await ApiService.post('booking-on-request', payload);
    commit('setPending', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
